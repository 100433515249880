<template>
    <div class="stepsdemo-content">
        <i class="pi pi-fw pi-money-bill" />
        <p>Payment Component Content via Child Route</p>
    </div>
</template>

<script>
export default {

}
</script>